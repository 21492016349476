@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&display=swap);
* {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
  list-style: none;
  border: 0;
  box-sizing: border-box;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

:root {
  --bs-blue: #2c8ef8;
  --bs-indigo: #727cf5;
  --bs-purple: #6b5eae;
  --bs-pink: #ff679b;
  --bs-red: #fa5c7c;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffbc00;
  --bs-green: #0acf97;
  --bs-teal: #02a8b5;
  --bs-cyan: #39afd1;
  --bs-white: #fff;
  --bs-gray: #98a6ad;
  --bs-gray-dark: #343a40;
  --bs-primary: #727cf5;
  --bs-secondary: #6c757d;
  --bs-success: #0acf97;
  --bs-info: #39afd1;
  --bs-warning: #ffbc00;
  --bs-danger: #fa5c7c;
  --bs-light: #eef2f7;
  --bs-dark: #313a46;
  --bs-main: #252525;
  --bs-main-black: #000000;
  --bs-main-gray: #e2e3e7;
  --bs-secondary-gray: #333333;
  --bs-text-gray: #5c5c5c;
  --bs-main-white: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  /* line-height: 1.5; */
  color: #5c5c5c;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow-x: hidden;
  /* overflow: hidden; */
}

html {
  background-color: #fff;
  min-height: 100%;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.375rem !important;
}

.mt-2 {
  margin-top: 0.75rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.375rem !important;
}

.me-2 {
  margin-right: 0.75rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.375rem !important;
}

.mb-2 {
  margin-bottom: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.375rem !important;
}

.ms-2 {
  margin-left: 0.75rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 4.5rem !important;
}

.ms-6 {
  margin-left: 6rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.375rem !important;
}

.pt-2 {
  padding-top: 0.75rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.375rem !important;
}

.pe-2 {
  padding-right: 0.75rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.375rem !important;
}

.pb-2 {
  padding-bottom: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.375rem !important;
}

.ps-2 {
  padding-left: 0.75rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 4.5rem !important;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.1;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 6px;
}

.initialism {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.75rem;
  color: #98a6ad;
}
.blockquote-footer::before {
  content: "\2014\A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.75rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.75rem;
  color: #98a6ad;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 12px;
  padding-right: var(--bs-gutter-x, 12px);
  padding-left: 12px;
  padding-left: var(--bs-gutter-x, 12px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }

  .cadastro_economia {
    margin-left: 1rem;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.375rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.375rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.75rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.75rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1.5rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1.5rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 2.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 2.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 4.5rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 4.5rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.375rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.375rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.75rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.75rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 2.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 2.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 4.5rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 4.5rem;
  }
}

.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.d-flex {
  display: flex !important;
}

.d-block {
  display: -ms-flexbox !important;
  display: block !important;
}

.float-end {
  float: right !important;
  width: 100px;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.text-center {
  text-align: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.d-none {
  display: none !important;
}

.text-muted {
  color: #98a6ad !important;
}
.logo {
  cursor: pointer;
}
.fw-normal {
  font-weight: 400 !important;
}
.fw-light {
  font-weight: 300 !important;
}
.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-line-through {
  -webkit-text-decoration-line: line-through !important;
          text-decoration-line: line-through !important;
}
.table-light {
  color: #313a46;
  border-color: #dbe0e5;
  background-color: #dbe0e5;
}

.table {
  --bs-table-bg: #fff;
  --bs-table-accent-bg: #fff;
  --bs-table-striped-color: #6c757d;
  --bs-table-striped-bg: #f1f3fa;
  --bs-table-active-color: #6c757d;
  --bs-table-active-bg: rgba(222, 226, 230, 0.4);
  --bs-table-hover-color: #6c757d;
  --bs-table-hover-bg: #f1f3fa;
  width: 100%;
  margin-bottom: 1.5rem;
  color: #6c757d;
  margin: auto;
  vertical-align: top;
  border-color: #eef2f7;
}
.btn {
  line-height: 2em;
}
.footer {
  font-size: 11px;
  padding-top: 14px;
}

.nowrap {
  white-space: nowrap;
}

.card-principal {
  color: #fff;
  margin: 1.2em 0;
  border-radius: 1.25rem;
  border: none;
  padding: 0.5em;
}
.card-activid {
  color: #5c5c5c;
  margin: 0.5em 0;
  border-radius: 0;
  border: none;
  padding: 0.5em;
  background-color: transparent;
}
.card-grafic {
  color: #5c5c5c;
  margin: 1.2em 0;
  border-radius: 1.25rem;
  padding: 0.5em;
}
.card-text {
  margin-bottom: 0;
}
.notification {
  color: #5c5c5c;
  text-decoration: none;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eef2f7;
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .card {
    margin: 50px auto 0;
    padding: 15px 5px 15px;
    border: 1px solid #eaeced;
    overflow: hidden;
  }
}

.rotated {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.text-red {
  color: #252525;
  color: var(--bs-main);
}

.text-white {
  color: #ffffff;
  color: var(--bs-main-white);
}

.text-gray {
  color: #e2e3e7;
  color: var(--bs-main-gray);
}

.text-gray-darker {
  color: #848485;
}

.text-black {
  color: #000000;
  color: var(--bs-main-black);
}

.link-text {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  /* text-align: center; */
}

.link-text:hover {
  border-bottom: 1px solid #fff;
}

.background-red {
  background-color: #252525;
  background-color: var(--bs-main);
}

.background-gray {
  background-color: #e2e3e7;
  background-color: var(--bs-main-gray);
}

.background-gray-darker {
  background-color: #333333;
  background-color: var(--bs-secondary-gray);
}

.background-black {
  background-color: #252525;
  background-color: var(--bs-main);
}

.background-black-true {
  background-color: #000000;
  background-color: var(--bs-main-black);
}

.background-blue {
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
}
.background-white {
  background-color: #ffffff;
  background-color: var(--bs-main-white);
}

.title-1 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 52px;
  line-height: 61px;
  color: #000000;
  color: var(--bs-main-black);
}

.title-2 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 37.5px;
  color: #000000;
  color: var(--bs-main-black);
}

.title-3 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 25.75px;
  color: #000000;
  color: var(--bs-main-black);
}

.title-4 {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 4.688rem;
  line-height: 82.42px;
  color: #252525;
  color: var(--bs-main);
}

.heading-2 {
  font-size: 48px;
  line-height: 57px;
  font-weight: 700;
}

.heading-3 {
  font-size: 22px;
  line-height: 25px;
}

.heading-4 {
  font-size: 20px;
  line-height: 22px;
}

.heading-1,
.heading-2,
.heading-3 {
  font-family: Roboto, sans-serif;
}

.large-container .heading-2 {
  font-size: 32px;
  color: #2c8ef8;
  color: var(--bs-blue);
  margin-left: 20px;
  width: 65%;
  line-height: 39px;
  font-weight: 700;
}

.section-title:before {
  content: "_";
  position: absolute;
  top: 0;
  left: 24px;
  width: 3px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.section-title:before {
  left: 15px;
}

.\--vertical-text {
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
}

.paragraph-1 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 32px;
  color: #5c5c5c;
  color: var(--bs-text-gray);
}

.paragraph-2 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 32px;
  color: #000000;
  color: var(--bs-main-black);
}

.paragraph-3 {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 32px;
  color: #ffffff;
  color: var(--bs-main-white);
}

.header {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999px;  */
  background-color: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
}

footer .background-red {
  padding: 20px 0 19px 106px;
}

footer .background-blue {
  padding: 20px 0 19px 106px;
}

footer {
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
}

footer .background-black {
  /* position: absolute;
  transform: translate(90%, -45%); */
  padding: 20px 37px 33px;
}

footer .background-black p {
  margin-top: 1.625rem;
}

footer .textos {
  /* transform: translate(100%, 0%); */
}
/* Float */
.hvr-float {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-out;
}
.hvr-float:hover,
.hvr-float:focus,
.hvr-float:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

.mdi {
  font-size: 30px;
  color: #ffffff;
  padding-right: 0.5rem;
}

.accordion-flush .accordion-item {
  /* display: flex; */
  margin-bottom: 5px;
}

.accordion-button {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 32px;
}

.accordion-button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px;
  background-color: #000;
  background: #ffc032;
}

.accordion-button:not(.collapsed) {
  color: #18191f;
  background-color: #f0f0f0;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  box-shadow: none;
}

.accordion-body {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #333;
}

.accordion-header {
  display: flex;
  width: 95%;
}

.accordion-header > h2 {
  width: 95%;
}

.bg-collapse {
  background-color: #848485 !important;
}

.collapse-panel .text .title.-small {
  font-size: 22px;
  line-height: 24px;
  padding-right: 65px;
}
.collapse-panel .icon.-arrow-down {
  right: 5%;
  top: 55%;
}

.collapse-panel.-small > .info p,
.collapse-panel > .info > p {
  text-transform: none;
  padding: 10px 0 32px;
  font-family: Georgia;
}

.collapse-panel.-small > .info p {
  font-size: 16px;
  line-height: 19px;
}

.faq > .container > .info > .collapse-container {
  max-width: 752px;
  width: 100%;
}

.faq > .container > .info > .collapse-container > * {
  margin-bottom: 8px;
}

.accordion-flush .accordion-item {
  /* display: flex; */
  margin-bottom: 5px;
}

.collapse-panel .icon.-arrow-down.-rotate {
  -webkit-transform: rotate(180deg) translateY(50%);
          transform: rotate(180deg) translateY(50%);
}

.collapse-panel {
  width: 100%;
  position: relative;
}

.collapse-panel .text .title.-small {
  text-transform: none;
  font-weight: 400;
}

.faq {
  background-color: #e0e0e0;
  padding: 64px 80px;
  margin-top: 39px;
  margin-bottom: 120px !important;
}

.faq > .container > .info {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
}

.faq > .container > .info > .collapse-container {
  max-width: 752px;
  width: 100%;
}

.faq > .container > .info > .collapse-container > * {
  margin-bottom: 8px;
}

.faq .schedule-conversation {
  background-color: #ffc032;
  max-width: 368px;
  height: 281px;
  width: 100%;
  padding: 24px 24px 32px;
  box-sizing: border-box;
  margin-left: 16px;
}

.faq .schedule-conversation > .heading-4 {
  height: auto;
  width: 320px;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
  font-weight: 400;
}

.faq .schedule-conversation > .contact {
  width: 320px;
  height: 80px;
  opacity: 0.99;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 35px;
}

.faq .schedule-conversation > .contact a,
.faq .schedule-conversation > .contact > span {
  color: #fff;
}

.faq .schedule-conversation > .contact > a {
  text-decoration: none;
}

.faq .schedule-conversation > .btn {
  margin-top: 32px;
  max-width: 320px !important;
}

.redes-sociais {
  margin-top: 3.688rem;
}

.institucional {
  /* margin-left: 7rem; */
}

.contato ul li a {
  font-size: 1rem;
  color: #ffffff;
}

.esquerda-landing {
  padding-top: 5rem;
}

.esquerda-landing button {
  width: 230px;
  height: 52px;
  background: #2c8ef8;
  background: var(--bs-blue);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 80px;
}

.esquerda-landing h1 {
  /* width: 485px; */
}

.esquerda-landing p {
  /* width: 390px;
  height: 128px; */
  font-size: 1.125rem;
  line-height: 32px;
  padding-left: 10px;
}

.esquerda-landing button a {
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 400;
  text-decoration: none;
}

.direita-landing img {
  width: 563px;
  height: 563px;
  padding-top: 3rem;
}

.beneficios-linha {
  width: 13px;
  height: 93px;
  margin-right: 20px;
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
}

.beneficios-linha-2 {
  width: 8px;
  height: 58px;
  /* margin-left: 8.5rem;
  margin-top: 5.3rem; */
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
}

.title-beneficios {
  margin-top: 5rem;
  padding-bottom: 3rem;
}

.beneficio-texto .caixa-texto {
  padding-bottom: 3rem;
}

.beneficio-texto .caixa-texto p {
  /* width: 400px; */
  line-height: 18.75px;
}

.plano {
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;
  margin-bottom: 8rem;
  background-color: #252525;
  background-color: var(--bs-main);
  border-radius: 10px;
}

.plano_caixa {
  /* position: absolute; */
}

.select-plano {
  -webkit-appearance: none;
  padding: 17px 40px 17px 17px;
  width: 330px;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 3px -2px #9098a9;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
  color: #5a667f;
}

.calculadora {
  background-color: #e2e3e7;
  background-color: var(--bs-main-gray);
  padding: 1.5rem;
  border-radius: 10px;
  margin-left: 2.5rem;
  height: 565px;
}

.select-plano:focus {
  outline: none;
  border-color: #0257b8;
  box-shadow: 0 0 0 2px rgba(#0077ff, 0.2);
}

.select-plano:hover {
  border-color: #0257b8;
}

.calculadora a.button {
  width: 129px;
  height: 55px;
  background: #2c8ef8;
  background: var(--bs-blue);
  border-radius: 38px;
  cursor: pointer;
}

.calculadora a.button:hover {
  background: #0257b8;
}

.caixa-plano-valores {
  position: relative;
  -webkit-transform: translate(90%, -10%);
          transform: translate(90%, -10%);
  z-index: 999;
  margin-right: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 7rem;
}

.plano-valores {
  background-color: #e2e3e7;
  background-color: var(--bs-main-gray);
  padding: 1.5rem;
  border-radius: 10px;
}

.calculadora a.button {
  -webkit-appearance: button;
  appearance: button;
  width: 370px;
  height: 66px;
  background: #2c8ef8;
  background: var(--bs-blue);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 250px;
  margin-top: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
}

.plano .background-gray a {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
}

.plano .background-gray a.button:hover {
  background: #0257b8;
}

.plano-valores a.button {
  -webkit-appearance: button;
  appearance: button;
  width: 160px;
  height: 55px;
  background: #2c8ef8;
  background: var(--bs-blue);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 250px;
  margin-top: 1.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plano-valores a {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
}

.plano-valores a.button:hover {
  background: #0257b8;
}

.title-plano-valores {
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  line-height: 37.5px;
  margin-left: 1.5rem;
  padding-bottom: 1.5rem;
}

.titulo-valores {
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.paragraph-valores {
  font-weight: 900;
  font-size: 33px;
  line-height: 32px;
}

.valores-caixa {
  padding: 0.5rem 1rem 1rem 0.5rem;
}

.card-red {
  border-radius: 40px;
}

.card-white {
  border-radius: 40px;
}

.circulo-cinza {
  width: 80px;
  height: 80px;
  background: #e2e3e7;
  border-radius: 50%;
}

.circulo-cinza img {
  width: 40%;
}

.assinatura .card {
  margin: 2rem;
}

.escolha {
  margin-top: 5rem;
}

.escolha-imagem img {
  width: 100%;
}

.economia-linha {
  width: 8px;
  height: 58px;
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
}

.economia .background-black {
  margin-top: 2rem;
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 2.5rem;
}

.economia-caixa {
  justify-content: flex-end;
}

.economia h2 {
  font-weight: 700;
}

.large-container .heading-2 {
  font-size: 32px;
  color: #2c8ef8;
  color: var(--bs-blue);
  margin-left: 20px;
  width: 65%;
  line-height: 39px;
  font-weight: 700;
}

.large-container > .container {
  padding: 0;
  margin: 0 auto;
}

.large-container .heading-2 {
  width: 100%;
}

.title-economia {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 52px;
  line-height: 61px;
  color: #000000;
}
.invisible {
  display: flex;
  justify-content: end;
}

/* .caixa-texto-card p {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
} */

.background-black .background-gray,
.background-black .background-white {
  height: 210px;
}

.background-black .background-white p,
.background-black .background-gray p {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #5c5c5c;
}

.retangulo-1 {
  background-color: #000;
  width: 24.63px;
  height: 5.8px;
}

.retangulo-2 {
  background-color: #000;
  width: 56.51px;
  height: 5.8px;
}

.retangulo-3 {
  background-color: #000;
  width: 37.67px;
  height: 5.8px;
}

.retangulo-4 {
  background-color: #000;
  width: 44.92px;
  height: 5.8px;
}

.retangulo-5 {
  background-color: #f0efea;
  width: 71px;
  height: 21.73px;
}

.text-fatura {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.perguntas-linha {
  min-width: 5px;
  height: 65px;
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
}

.perguntas {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.card-perguntas-red {
  min-width: 390px;
  height: 288px;
  background: #252525;
  background: var(--bs-main);
  border-radius: 4px;
}

.card-perguntas-red h3 {
  padding: 1rem;
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: #ffffff;
}

.card-perguntas-red p {
  padding: 1rem;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
}

.esquerda-landing .landing-button {
  width: 230px;
  height: 52px;
  background: #0077ff;
  color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 80px;
}

@media screen and (max-width: 576px) {
  .esquerda-landing {
    margin-left: 1rem;
  }

  .caixa-plano-valores .ms-4 {
    margin-left: 0rem;
  }

  .card-red {
    margin-top: 2rem;
  }

  .assinatura-texto {
    margin-left: 1.5rem;
  }

  .escolha-imagem {
    margin-right: 2rem;
  }

  .visible {
    display: none;
  }

  .invisible {
    display: flex;
    justify-content: center;
  }

  .caixa-texto-card {
    padding-bottom: 2rem;
  }

  .caixa-texto-card-quebra {
    padding-bottom: 0;
  }

  .economia-imagem {
    display: none;
  }

  .card-perguntas-red {
    margin-top: 2rem;
  }

  .textos {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .plano {
    justify-content: center;
    position: static;
  }

  .calculadora {
    margin-left: 0;
    margin-top: 3rem;
  }

  .caixa-plano-valores {
    margin-right: 0;
    margin-bottom: 3rem;
  }

  .caixa-vazia {
    display: none;
  }

  .card-white {
    margin-top: 2rem;
  }

  .card-red {
    margin-top: 2rem;
  }

  .direita-landing img {
    width: 100%;
  }
  .rotated {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg);
  }

  .economia-caixa {
    justify-content: center;
    align-items: center;
  }

  .caixa-texto-card-quebra {
    margin-top: 2rem;
  }

  .economia .background-black {
    padding-right: 0;
  }

  .invisible {
    display: none;
  }

  .caixa-footer {
    position: relative;
    justify-content: space-between;
  }

  .plano_caixa {
    position: static;
  }

  .caixa-plano-valores {
    position: static;
    -webkit-transform: none;
            transform: none;
  }
}

@media screen and (min-width: 1200px) {
  .visible {
    display: none;
  }

  footer .background-black {
    margin-right: 2rem;
  }
}
/* * Cadastro */

.progress_1 {
  padding: 2px;
  width: 70%;
  background-color: #e2e3e7;
  height: 6px;
}

.progress_1 .progress_bar_1 {
  height: 6px;
  width: 25%;
  background-color: var(--color-primary);
  -webkit-animation: fill-bar-1 2s;
          animation: fill-bar-1 2s;
}

@-webkit-keyframes fill-bar-1 {
  from {
    width: 0%;
  }
  to {
    width: 25%;
  }
}

@keyframes fill-bar-1 {
  from {
    width: 0%;
  }
  to {
    width: 25%;
  }
}

.progress_2 {
  padding: 2px;
  width: 70%;
  background-color: #e2e3e7;
  height: 6px;
}

.progress_2 .progress_bar_2 {
  height: 6px;
  width: 50%;
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
  -webkit-animation: fill-bar-2 2s;
          animation: fill-bar-2 2s;
}

@-webkit-keyframes fill-bar-2 {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}

@keyframes fill-bar-2 {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}

.progress_3 {
  padding: 2px;
  width: 70%;
  background-color: #e2e3e7;
  height: 6px;
}

.progress_3 .progress_bar_3 {
  height: 6px;
  width: 70%;
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
  -webkit-animation: fill-bar-3 2s;
          animation: fill-bar-3 2s;
}

@-webkit-keyframes fill-bar-3 {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}

@keyframes fill-bar-3 {
  from {
    width: 0%;
  }
  to {
    width: 70%;
  }
}

.progress_4 {
  padding: 2px;
  width: 70%;
  background-color: #e2e3e7;
  height: 6px;
}

.progress_4 .progress_bar_4 {
  height: 6px;
  width: 100%;
  background-color: #2c8ef8;
  background-color: var(--bs-blue);
  -webkit-animation: fill-bar-4 2s;
          animation: fill-bar-4 2s;
}

@-webkit-keyframes fill-bar-4 {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@keyframes fill-bar-4 {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.cadastro_background {
  background-color: #f0efea;
  width: 100%;
  height: 100%;
}

.cadastro {
  padding-top: 4.625rem;
  padding-bottom: 4.625rem;
}
.cadastro_titulo {
  font-weight: 700;
  font-size: 32px;
  line-height: 37px;
  color: #000000;
  font-family: Roboto;
  margin-bottom: 1.625rem;
}

.termos_titulo {
  font-family: Roboto;
  font-weight: 700;
  font-size: 52px;
  line-height: 61px;
  color: #000000;
  margin-bottom: 1.625rem;
}

.cadastro_label {
  font-family: Roboto;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}

.cadastro-2_label {
  margin-top: 10px;
  cursor: pointer;
  font-size: 18px;
  line-height: 21px;
  color: #7f7f7f;
}

input[type="file"] {
  display: none;
}

.selecionar-fatura {
  margin-bottom: 1.625rem;
}

.form-control {
  font-size: 18px;
  line-height: 21px;
  padding: 1.5rem 0;
  border-bottom: 2px solid #e2e3e7;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 1.625rem;
}

.form-control:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.cadastro_btn {
  width: 176px;
  height: 52px;
  background: #2c8ef8;
  background: var(--bs-blue);
  border-radius: 80px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
}

.cadastro_btn:hover {
  background: #0257b8;
}

.cadastro__voltar_btn {
  width: 176px;
  height: 52px;
  background: #357ed1;
  border-radius: 80px;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
}

.cadastro_economia {
  /* margin-left: 1rem; */
  height: 100%;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.cadastro_fatura {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.cadastro_footer_imagem {
  padding: 2.25rem 0 2.25rem 6.25rem;
}

.cadastro_contato {
  text-decoration: none;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
}

@media screen and (max-width: 576px) {
  .cadastro {
    padding-top: 1rem;
    /* padding-bottom: 4.125rem; */
  }
  .cadastro_economia {
    /* margin-left: 1rem; */
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .cadastro_economia {
    margin-top: 1.5rem;
  }

  .cadastro_footer_imagem {
    padding: 2.25rem 0 2.25rem 2.25rem;
  }
}

.text-bold {
  font-weight: bolder;
}

.paragraph_termo {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
}

.cadastro_fatura .card-body::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.cadastro_fatura .card-body::-webkit-scrollbar-track {
  background: #e7e5e5; /* color of the tracking area */
}

.cadastro_fatura .card-body::-webkit-scrollbar-thumb {
  background-color: #2c8ef8;
  background-color: var(--bs-blue); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  /* border: 3px solid #fff;  creates padding around scroll thumb */
}
.paragraph-economia-footer {
  font-family: "Roboto";
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #ffffff;
  /* width: 300px; */
}

.valor-total {
  font-family: "Manrope";
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.adesao_formulario_documentos_custombutton__30h7h {
    background-color: grey !important;
    width: 100%;
    max-width: 260px;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    float: right;
}

.adesao_formulario_documentos_btn__1So_Z:hover {
    color: white !important;
}

.adesao_formulario_documentos_custombutton__30h7h span{
    display: inline-block;
    margin: 8px 10px 0 7px;
}
.adesao_formulario_documentos_ajuste__2J6r4{
    float: left !important;
    background-color: grey !important;
}
